/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Leads</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                    <div class="d-flex">
                      <div>
                        <h6>
                          List of Leads
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h6>
                      </div>
                      <div class="ml-3">
                        <b-button variant="success" @click="generateExcelForUserLead()"
                          >Download</b-button
                        >
                      </div>
                      <div class="ml-auto">
                        <form class="search-form mr-form">
                          <input
                            type="text "
                            class="form-control"
                            v-model="searchText"
                            @input="viewLeadsForAdmin(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                      <div>
                        <router-link to="/createUserLead" append>
                          <b-button class="createBtn" variant="success">Create</b-button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Check In</th>
                          <th>Check Out</th>
                          <th>No Of Guest</th>
                          <th>Hotel Name</th>
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in leads" v-bind:key="item._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <td>
                            <div>{{ item.name }}</div>
                          </td>
                          <td>
                            <div>{{ item.email }}</div>
                          </td>
                          <td>
                            <div>{{ item.phone }}</div>
                          </td>
                          <td>
                            <div>{{ item.checkIn | moment("DD/MM/YYYY") }}</div>
                          </td>
                          <td>
                            <div>{{ item.checkOut | moment("DD/MM/YYYY") }}</div>
                          </td>
                          <td>
                            <div>{{ item.noOfGuest }}</div>
                          </td>
                          <td>
                            <div>{{ item.hotel.name }}</div>
                          </td>
                          <td>
                            <div>{{ item.createdAt | moment("LLL") }}</div>
                          </td>
                          <td>
                            <router-link
                              class="btn btn-primary"
                              :to="'/viewLeadDetail/' + item._id"
                              append
                            >
                              <b-icon-eye></b-icon-eye>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="leads.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="leads.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewLeadsForAdmin(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      currentPage: 1,
      searchText: "",
      leads: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: []
    };
  },
  created() {
    this.viewLeadsForAdmin(this.page);
  },
  methods: {
    viewLeadsForAdmin(pageNo) {
      this.leads = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      this.formData.leadThroughType = "WedDestinations";
      service.viewLeadsForAdmin(this.formData, data => {
        if (data.status === 200) {
          this.leads = data.data.result;
          this.totalCount = data.data.TotalCount;
        } else {
        }
      });
    },
    generateExcelForUserLead() {
      var obj = {
        leadThrough: "WedDestinations"
      };
      service.generateExcelForUserLead(obj, data => {
        service.downloadExcel(data, "Lead_List");
      });
    }
  }
};
</script>
